import React from "react";
import { useParams, Link } from "react-router-dom";

const AboutUsers = (props) => {
  const params = useParams();
  const user = props.user.find((el) => el.id === +params.usId);
  return (
    <div className="aboutUserWrapper">
      <div className="global">
        <div className="header">
          <img
            className="userimage"
            src="https://findicons.com/files/icons/1994/vista_style_business_and_data/256/users.png"
            alt="user"
          />
          <h1 className="DescName">
            "With our solid Marketing and Promotion concept, we bring your brand
            closer to your audience"
          </h1>
          <h1 style={styles.kunle}>Kunle Onime</h1>
          <h1 style={styles.kunle_title}>MPC Int'l </h1>
        </div>
      </div>
    </div>
  );
};
const styles = {
  kunle: {
    fontSize: "20px",
    fontWeight: "900",
    color: "red"
  },
  kunle_title: {
    fontSize: "20px",
    fontWeight: "900",
    color: "#3681D1"
  },
}
export default AboutUsers;
